@import "../../../less/_variables/color.less";
@import "../../../less/_variables/typography.less";

@eekUnknown: #333333;
@eekAPlus: #42934a;
@eekA: #42934a;
@eekB: #6aab46;
@eekC: #cbd244;
@eekD: #fcec4f;
@eekE: #f2bb40;
@eekF: #dc6d2d;
@eekG: #d12e26;
@eekH: #d12e26;

.label-default(@color) {
	@svgColor: replace(@color, "#", "%23");

	width: 44px;
	height: 22px;
	padding-left: 5px;
	font-size: @fontSizeS;
	background-image: url('data:image/svg+xml;utf8,<svg width="44" height="22" viewBox="0 0 44 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M28 0H0V22H28L44 11L28 0Z" fill="@{svgColor}"/></svg>');
}

.label-small(@color) {
	@svgColor: replace(@color, "#", "%23");

	width: 24px;
	height: 16px;
	padding-left: 3px;
	font-size: @fontSizeXS;
	line-height: 16px;
	background-image: url('data:image/svg+xml;utf8,<svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 0H0V16H16L24 8L16 0Z" fill="@{svgColor}"/></svg>');
}

.label-vertical(@color) {
	@svgColor: replace(@color, "#", "%23");

	width: 18px;
	height: 20px;
	padding: 0;
	font-size: @fontSizeXS;
	line-height: 16px;
	text-align: center;
	background-image: url('data:image/svg+xml;utf8,<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18 12.7273V0L0 0V12.7273L9 20L18 12.7273Z" fill="@{svgColor}"/></svg>');
}

.eec-label {
	@parent: eec-label;
	font-weight: 700;

	&-small {
		&.@{parent}-APlus {
			.label-small(@eekAPlus);
		}

		&.@{parent}-A {
			.label-small(@eekA);
		}

		&.@{parent}-B {
			.label-small(@eekB);
		}

		&.@{parent}-C {
			.label-small(@eekC);
		}

		&.@{parent}-D {
			.label-small(@eekD);
		}

		&.@{parent}-E {
			.label-small(@eekE);
		}

		&.@{parent}-F {
			.label-small(@eekF);
		}

		&.@{parent}-G {
			.label-small(@eekG);
		}

		&.@{parent}-H {
			.label-small(@eekH);
		}

		&.@{parent}-unknown {
			@svgColor: replace(@eekUnknown, "#", "%23");

			width: 24px;
			height: 16px;
			padding-left: 3px;
			font-size: @fontSizeXS;
			line-height: 16px;
			background-image: url('data:image/svg+xml;utf8,<svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 15.5V0.5H15.7929L23.2929 8L15.7929 15.5H0.5Z" fill="@{svgColor}" stroke="white"/></svg>');
		}
	}

	&-vertical {
		&.@{parent}-APlus {
			.label-vertical(@eekAPlus);
		}

		&.@{parent}-A {
			.label-vertical(@eekA);
		}

		&.@{parent}-B {
			.label-vertical(@eekB);
		}

		&.@{parent}-C {
			.label-vertical(@eekC);
		}

		&.@{parent}-D {
			.label-vertical(@eekD);
		}

		&.@{parent}-E {
			.label-vertical(@eekE);
		}

		&.@{parent}-F {
			.label-vertical(@eekF);
		}

		&.@{parent}-G {
			.label-vertical(@eekG);
		}

		&.@{parent}-H {
			.label-vertical(@eekH);
		}
	}

	&-APlus {
		.label-default(@eekAPlus);
		color: @gray100;
	}

	&-A {
		.label-default(@eekA);
		color: @gray100;
	}

	&-B {
		.label-default(@eekB);
	}

	&-C {
		.label-default(@eekC);
	}

	&-D {
		.label-default(@eekD);
	}

	&-E {
		.label-default(@eekE);
	}

	&-F {
		.label-default(@eekF);
	}

	&-G {
		.label-default(@eekG);
		color: @gray100;
	}

	&-H {
		.label-default(@eekH);
		color: @gray100;
	}

	&-unknown {
		@svgColor: replace(@eekUnknown, "#", "%23");

		width: 44px;
		height: 22px;
		padding-left: 5px;
		font-size: @fontSizeS;
		color: @gray100;
		background-image: url('data:image/svg+xml;utf8,<svg width="44" height="22" viewBox="0 0 44 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 21.5V0.5H27.8447L43.1174 11L27.8447 21.5H0.5Z" fill="@{svgColor}" stroke="white"/></svg>');
	}
}
