@import "../../../less/_mixins/typography.less";
@import "../../../less/_mixins/general.less";
@import "../../../less/_variables/general.less";

/* text colors */
@button-inversive-text-color: #ffffff;
@button-inversive-text-color-dark: #333333;

@button-disabled-text-color: #adadad;
@button-disabled-text-color-dark: #adadad;

@button-default-text-color: #333333;
@button-default-text-color-dark: #ffffff;

@button-danger-text-color: #a8071a;
@button-danger-text-color-dark: #ffa39e;

@button-success-text-color: #237804;
@button-success-text-color-dark: #237804;

/* FILLED DEFAULT */
@filled-default-default: #333333;
@filled-default-default-dark: #ffffff;

@filled-default-hover: #4c4c4c;
@filled-default-hover-dark: #e0e0e0;

@filled-default-active: #676767;
@filled-default-active-dark: #e0e0e0;

@filled-default-disabled: #e0e0e0;
@filled-default-disabled-dark: #4c4c4c;

/* FLOATING DEFAULT */
@floating-default-default: #333333;
@floating-default-default-dark: #ffffff;

@floating-default-hover: #4c4c4c;
@floating-default-hover-dark: #e0e0e0;

@floating-default-active: #676767;
@floating-default-active-dark: #e0e0e0;

@floating-default-disabled: #e0e0e0;
@floating-default-disabled-dark: #4c4c4c;

/* FILLED WEAK */
@filled-weak-default: #eaeaea;
@filled-weak-default-dark: #4c4c4c;

@filled-weak-hover: #e0e0e0;
@filled-weak-hover-dark: #676767;

@filled-weak-active: #eaeaea;
@filled-weak-active-dark: #4c4c4c;

@filled-weak-disabled: #e0e0e0;
@filled-weak-disabled-dark: #676767;

/* OUTLINE STRONG */
@outline-strong-border: #333333;
@outline-strong-border-dark: #ffffff;

@outline-strong-default: #ffffff;
@outline-strong-default-dark: #333333;

@outline-strong-hover: #f5f5f5;
@outline-strong-hover-dark: #4c4c4c;

@outline-strong-active: #ffffff;
@outline-strong-active-dark: #333333;

@outline-strong-disabled: #e0e0e0;
@outline-strong-disabled-dark: #4c4c4c;

/* TEXT DEFAULT */
@text-default-default: #ffffff;
@text-default-default-dark: #333333;

@text-default-hover: #f5f5f5;
@text-default-hover-dark: #4c4c4c;

@text-default-active: #676767;
@text-default-active-dark: #e0e0e0;

@text-default-disabled: #ffffff;
@text-default-disabled-dark: #333333;

/* ELEVATED INVERSIVE */
@elevated-inversive-default: #ffffff;
@elevated-inversive-default-dark: #333333;

@elevated-inversive-hover: #f5f5f5;
@elevated-inversive-hover-dark: #4c4c4c;

@elevated-inversive-active: #eaeaea;
@elevated-inversive-active-dark: #676767;

@elevated-inversive-disabled: #e0e0e0;
@elevated-inversive-disabled-dark: #4c4c4c;

/* FILLED BRAND */
@filled-brand-default: #00ffd0;
@filled-brand-default-dark: #55e6cb;

@filled-brand-hover: #55e6cb;
@filled-brand-hover-dark: #2bdfbe;

@filled-brand-active: #2bdfbe;
@filled-brand-active-dark: #00ffd0;

@filled-brand-disabled: #e0e0e0;
@filled-brand-disabled-dark: #4c4c4c;

/* OUTLINE WEAK */
@outline-weak-border: #c4c4c4;
@outline-weak-border-dark: #8f8f8f;

@outline-weak-hover: #4c4c4c;
@outline-weak-hover-dark: #e0e0e0;

@outline-weak-active: #676767;
@outline-weak-active-dark: #e0e0e0;

@outline-weak-disabled: #e0e0e0;
@outline-weak-disabled-dark: #4c4c4c;

/* TEXT DANGER */
@text-danger-default: #a8071a;
@text-danger-default-dark: #ffa39e;

@text-danger-hover: #cf1322;
@text-danger-hover-dark: #f5222d;

@text-danger-active: #f5222d;
@text-danger-active-dark: #ff7875;

@text-danger-disabled: #adadad;
@text-danger-disabled-dark: #adadad;

/* FILLED DANGER */
@filled-danger-default: #a8071a;
@filled-danger-default-dark: #ffa39e;

@filled-danger-hover: #cf1322;
@filled-danger-hover-dark: #f5222d;

@filled-danger-active: #f5222d;
@filled-danger-active-dark: #ff7875;

@filled-danger-disabled: #e0e0e0;
@filled-danger-disabled-dark: #4c4c4c;

/* FILLED DANGER WEAK */
@filled-danger-weak-default: #fff1f0;
@filled-danger-weak-default-dark: #ffccc7;

@filled-danger-weak-hover: #ffccc7;
@filled-danger-weak-hover-dark: #ffa39e;

@filled-danger-weak-active: #ffa39e;
@filled-danger-weak-active-dark: #ffccc7;

@filled-danger-weak-disabled: #e0e0e0;
@filled-danger-weak-disabled-dark: #4c4c4c;

/* FILLED SUCCESS WEAK */
@filled-success-weak-default: #f6ffed;
@filled-success-weak-default-dark: #f6ffed;

@filled-success-weak-hover: #d9f7be;
@filled-success-weak-hover-dark: #d9f7be;

@filled-success-weak-active: #b7eb8f;
@filled-success-weak-active-dark: #b7eb8f;

@filled-success-weak-disabled: #e0e0e0;
@filled-success-weak-disabled-dark: #4c4c4c;

/* ---------- BUTTONS ---------- */
.button-rounded {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	border: none;
	cursor: pointer;
	font: inherit;
	outline: none;
	user-select: none;
	text-decoration: none;
	border-radius: 999px;

	font-family: @fontFamilyStandard-theme-cosma;
	font-weight: 700;
	gap: 8px;

	transition: all 300ms ease-in-out;

	.button-rounded-left-icon,
	.button-rounded-right-icon {
		display: inline-flex;
		align-items: center;
		justify-content: center;

		svg {
			width: 16px;
			height: 16px;
		}
	}

	/* ---------- SIZES ---------- */
	&.button-rounded-size-medium {
		height: 40px;
		padding: 14px 16px;
		font-size: 14px;
		line-height: 16px;
	}

	&.button-rounded-size-large {
		height: 48px;
		padding: 16px 24px;
		font-size: 16px;
		line-height: 16px;
	}

	/* ---------- WIDTH MODES ---------- */
	&.button-rounded-width-hug {
		width: fit-content;
	}

	&.button-rounded-width-fixed {
		width: 240px;
		.button-rounded-right-icon {
			position: absolute;
			right: 24px;
		}
	}

	/* ---------- VARIANTS ---------- */
	&.button-rounded-filled-default {
		.color-scheme(background-color, @filled-default-default, @filled-default-default-dark);
		.color-scheme(color, @button-inversive-text-color, @button-inversive-text-color-dark);

		&:hover:not(:disabled) {
			.color-scheme(background-color, @filled-default-hover, @filled-default-hover-dark);
		}
		&:active:not(:disabled) {
			.color-scheme(background-color, @filled-default-active, @filled-default-active-dark);
		}
		&:disabled {
			.color-scheme(background-color, @filled-default-disabled, @filled-default-disabled-dark);
			cursor: not-allowed;
		}
	}

	&.button-rounded-floating-default {
		.color-scheme(background-color, @floating-default-default, @floating-default-default-dark);
		.color-scheme(color, @button-inversive-text-color, @button-inversive-text-color-dark);
		box-shadow: 0px 4px 12px 0px #333333;

		&:hover:not(:disabled) {
			.color-scheme(background-color, @floating-default-hover, @floating-default-hover-dark);
		}
		&:active:not(:disabled) {
			.color-scheme(background-color, @floating-default-active, @floating-default-active-dark);
		}
		&:disabled {
			.color-scheme(background-color, @floating-default-disabled, @floating-default-disabled-dark);
			cursor: not-allowed;
		}
	}

	&.button-rounded-filled-weak {
		.color-scheme(background-color, @filled-weak-default, @filled-weak-default-dark);
		.color-scheme(color, @button-default-text-color, @button-default-text-color-dark);

		&:hover:not(:disabled) {
			.color-scheme(background-color, @filled-weak-hover, @filled-weak-hover-dark);
		}
		&:active:not(:disabled) {
			.color-scheme(background-color, @filled-weak-active, @filled-weak-active-dark);
		}
		&:disabled {
			.color-scheme(background-color, @filled-weak-disabled, @filled-weak-disabled-dark);
			cursor: not-allowed;
		}
	}

	&.button-rounded-outline-strong {
		.color-scheme(background-color, @outline-strong-default, @outline-strong-default-dark);
		.color-scheme(border-color, @outline-strong-border, @outline-strong-border-dark);
		.color-scheme(color, @button-default-text-color, @button-default-text-color-dark);
		box-shadow: inset 0 0 0 1px @outline-strong-border;

		&:hover:not(:disabled):not(:focus) {
			.color-scheme(background-color, @outline-strong-hover, @outline-strong-hover-dark);
			box-shadow: inset 0 0 0 2px @outline-strong-border;

			.color-scheme(border-color, @outline-strong-border, @outline-strong-border-dark);
		}
		&:active:not(:disabled) {
			box-shadow: inset 0 0 0 2px @outline-strong-border;

			.color-scheme(border-color, @outline-strong-border, @outline-strong-border-dark);
		}
		&:disabled {
			box-shadow: inset 0 0 0 2px @outline-strong-disabled;
			.color-scheme(border-color, @outline-strong-disabled, @outline-strong-disabled-dark);
			cursor: not-allowed;
		}
		&:focus-visible {
			box-shadow: inset 0 0 0 2px #1677ff;
		}
	}

	&.button-rounded-text-default {
		.color-scheme(background-color, @text-default-default, @text-default-default-dark);
		.color-scheme(color, @button-default-text-color, @button-default-text-color-dark);

		&:hover:not(:disabled) {
			.color-scheme(background-color, @text-default-hover, @text-default-hover-dark);
		}
		&:active:not(:disabled) {
			.color-scheme(background-color, @text-default-active, @text-default-active-dark);
		}
		&:disabled {
			.color-scheme(background-color, @text-default-disabled, @text-default-disabled-dark);
			cursor: not-allowed;
		}
	}

	&.button-rounded-elevated-inversive {
		.color-scheme(background-color, @elevated-inversive-default, @elevated-inversive-default-dark);
		.color-scheme(color, @button-default-text-color, @button-default-text-color-dark);
		box-shadow: 0px 2px 4px 0px #00000026;

		&:hover:not(:disabled) {
			.color-scheme(background-color, @elevated-inversive-hover, @elevated-inversive-hover-dark);
		}
		&:active:not(:disabled) {
			.color-scheme(background-color, @elevated-inversive-active, @elevated-inversive-active-dark);
		}
		&:disabled {
			.color-scheme(background-color, @elevated-inversive-disabled, @elevated-inversive-disabled-dark);
			cursor: not-allowed;
		}
	}

	&.button-rounded-filled-brand {
		background-color: @filled-brand-default;
		color: @button-default-text-color;

		&:hover:not(:disabled) {
			.color-scheme(background-color, @filled-brand-hover, @filled-brand-hover-dark);
		}
		&:active:not(:disabled) {
			.color-scheme(background-color, @filled-brand-active, @filled-brand-active-dark);
		}
		&:disabled {
			.color-scheme(background-color, @filled-brand-disabled, @filled-brand-disabled-dark);
			cursor: not-allowed;
		}
	}

	&.button-rounded-outline-weak {
		background-color: transparent;

		.color-scheme(border-color, @outline-weak-border, @outline-weak-border-dark);
		.color-scheme(color, @button-default-text-color, @button-default-text-color-dark);
		box-shadow: inset 0 0 0 1px @outline-weak-border;

		&:hover:not(:disabled):not(:focus) {
			.color-scheme(border-color, @outline-weak-hover, @outline-weak-hover-dark);
			.color-scheme(color, @outline-weak-hover, @outline-weak-hover-dark);
			box-shadow: inset 0 0 0 2px @outline-weak-hover;
		}
		&:active:not(:disabled) {
			.color-scheme(border-color, @outline-weak-active, @outline-weak-active-dark);
			.color-scheme(color, @outline-weak-active, @outline-weak-active-dark);
			box-shadow: inset 0 0 0 2px @outline-weak-active;
		}
		&:disabled {
			.color-scheme(border-color, @outline-weak-disabled, @outline-weak-disabled-dark);
			.color-scheme(color, @outline-weak-disabled, @outline-weak-disabled-dark);
			box-shadow: inset 0 0 0 2px @outline-weak-disabled;
			cursor: not-allowed;
		}
		&:focus-visible {
			box-shadow: inset 0 0 0 2px #1677ff;
		}
	}

	&.button-rounded-text-danger {
		.color-scheme(background-color, transparent, transparent);
		.color-scheme(color, @button-danger-text-color, @button-danger-text-color-dark);

		&:hover:not(:disabled) {
			.color-scheme(color, @text-danger-hover, @text-danger-hover-dark);
		}
		&:active:not(:disabled) {
			.color-scheme(color, @text-danger-active, @text-danger-active-dark);
		}
		&:disabled {
			.color-scheme(color, @text-danger-disabled, @text-danger-disabled-dark);
			cursor: not-allowed;
		}
	}

	&.button-rounded-filled-danger {
		.color-scheme(background-color, @filled-danger-default,  @filled-danger-weak-default);
		.color-scheme(color, @button-inversive-text-color,  @button-danger-text-color);

		&:hover:not(:disabled) {
			.color-scheme(background-color, @filled-danger-hover, @filled-danger-weak-hover-dark);
		}
		&:active:not(:disabled) {
			.color-scheme(background-color, @filled-danger-active, @filled-danger-active-dark);
		}
		&:disabled {
			.color-scheme(background-color, @filled-danger-disabled, @filled-danger-disabled-dark);
			cursor: not-allowed;
		}
	}

	&.button-rounded-filled-danger-weak {
		background-color: @filled-danger-weak-default;
		color: @button-danger-text-color;

		&:hover:not(:disabled) {
			.color-scheme(background-color, @filled-danger-weak-hover, @filled-danger-weak-hover-dark);
		}
		&:active:not(:disabled) {
			.color-scheme(background-color, @filled-danger-weak-active, @filled-danger-weak-active-dark);
		}
		&:disabled {
			.color-scheme(background-color, @filled-danger-weak-disabled, @filled-danger-weak-disabled-dark);
			cursor: not-allowed;
		}
	}

	&.button-rounded-filled-success-weak {
		.color-scheme(background-color, @filled-success-weak-default, @filled-success-weak-default-dark);
		.color-scheme(color, @button-success-text-color, @button-success-text-color-dark);

		&:hover:not(:disabled) {
			.color-scheme(background-color, @filled-success-weak-hover, @filled-success-weak-hover-dark);
		}
		&:active:not(:disabled) {
			.color-scheme(background-color, @filled-success-weak-active, @filled-success-weak-active-dark);
		}
		&:disabled {
			.color-scheme(background-color, @filled-success-weak-disabled, @filled-success-weak-disabled-dark);
			cursor: not-allowed;
		}
	}

	&:focus-visible {
		box-shadow: inset 0 0 0 2px #1677ff;
	}
}
