@import "../../../less/_variables/typography.less";
@import "../../../less/_variables/spacing.less";
@import "../../../less/_variables/color-palette.less";


.section-title {
	@parent: section-title;
  height: auto;
  gap: @lapAndUpSpacingXS;
  
  font-family: @fontFamilyStandard-theme-cosma;
  color: @gray1000;

  &-large {
		& .@{parent}-heading {
      font-size: 34px;
      line-height: 44px;
		}
  }

  &-bold {
		& .@{parent}-heading {
      font-weight: 700;
		}
  }

  &-heading {
    font-size: 26px;
    line-height: 36px;
    font-weight: 400;
  }

  &-description {
    font-size: 20px;
    line-height: 30px;
  }
}
